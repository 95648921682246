body {
  height: 100%
}

#root {
  background: #f9fbfd;
}

.App {
  text-align: center;
  background: #f9fbfd;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none !important;
}

.skeleton {
  background-color: #d8d9d9;
  animation: skeleton-animation 10s infinite linear;
}

@keyframes skeleton-animation {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.2;
  }
}
/*  Promjena boje popovera  */
.popover-danger  {
  background-color: #cfe2ff  !important;
}

.popover-danger > .popover-arrow::after {
  border-right-color: #cfe2ff  !important;
}
/*React select input custom klasa/prilagođavanje bootstrapu*/
.select_bootstrapstil__control {
 height: calc(3.5rem + 2px);
 border: 1px solid #ced4da !important; 
 border-radius: .375rem !important;
}

.select_bootstrapstil__control--is-focused {
  border-color: #86b7fe !important;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25) !important;
 }
 .select_bootstrapstil__placeholder {
  color:#212529 !important;
 }
 .select_bootstrapstil__menu {   /*fix jer bootstrap floating label ima veći z-index po defaultu (2)*/
z-index: 3 !important;
margin-top: 4px !important;
 }

 .form-floating>label {   /*fix kada je preko floating inputa neki popover (npr date picker)*/
  z-index: 1 !important;
 }

/* Situacija kada je element pinovan za top */
.pinovan {
  position: sticky;
  top: -1px;
  /* ➜ the trick */

  padding: 1em;
  padding-top: calc(1em + 1px);
  /* ➜ compensate for the trick */

  background: salmon;
  transition: .1s;
}

div.obavezno {
  border-color: hsl(47deg 65% 84%);
  background-color: hsl(47deg 87% 94%);
  box-shadow: 0 1px 2px hsl(0deg 0% 0% / 5%),
    0 1px 4px hsl(0deg 0% 0% / 5%),
    0 2px 8px hsl(0deg 0% 0% / 5%);
}

div.obavezno .card-header {
  box-shadow: none !important;
  background-color: hsl(47deg 83% 91%);
}

[type=button].obavezno {
  background-color: hsl(47deg 83% 91%);
  border-color: hsl(47deg 50% 79%);
  box-shadow: 0 1px 2px hsl(0deg 0% 0% / 5%), 0 1px 4px hsl(0deg 0% 0% / 5%), 0 2px 8px hsl(0deg 0% 0% / 5%);
  color: #212529;
}

.modal-mojiPredmeti {
  width: 40% !important;
  max-width: none!important;
}

.noradius > span {
border-radius: 0 !important;
min-width: 180px;
}

.noradius > input {
  border-radius: 0 !important;
}

.obavezaninput {
  background: rgb(253, 247, 227) !important;
}

.alertV2 {
  border-width: 0 0 0 6px !important;
}


/*.list {
  box-shadow: 0 2px 10px rgb(0 0 0 / 14%), 0 4px 12px rgb(0 0 0 / 8%);
  padding: 20px 30px 20px 30px;
  margin: 5px 0 25px 0;
  border-bottom: 1px solid #d1d7dc;
  background: white;
}*/
.list {
  box-shadow: 0 3px 6px rgba(140, 149, 159, 0.15);
  padding: 20px 30px 20px 30px;
  margin: 5px 0 25px 0;
  background: white;
  border-color: hsla(210, 18%, 87%, 1);
  border: 1px solid #d0d7de;
}


.acinput__control {
  border-radius: 0px !important;
}

.acinput__control:hover {
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}

.acinput__value-container {
  max-width: 160px !important;
}

.acinput__input {
  max-width: 100px !important;
  min-width: 100px !important;
}

.datumpicker .form-control,
.datumpicker .form-control-plaintext {
  padding: 1rem .75rem;
}


.LPinputEditable {
  width: auto !important;
  display: unset !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  padding: 4px 6px 0px 6px !important;
}

.LPinputEditable:focus {
  color: #212529 !important;
  background-color: #fff !important;
  outline: 0 !important;
  box-shadow: none !important;

}


.LPspanEditable {
  border: 0;
  padding: 4px 6px 4px 6px;
}

.LPspanEditable:hover {
  cursor: pointer;
}

.navbar-nav .nav-link.active {
  border-bottom: 3px solid;
}

.navbar-expand .navbar-nav .nav-link {
padding-bottom: 5px;
}